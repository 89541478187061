.handle {
    color:white;
    height:65px;
    width:25px;
    background:rgba(65,68,80,.317);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid #ffffff6b 1px;
    pointer-events: auto;
    &.left {
        border-left:none;
        border-radius: 0px 8px 8px 0px;
        &.open{
            left:380px;
        }
        &.collapsed{
            left:0px;
        }
    }
    &.right {
        border-right:none;
        border-radius: 8px 0px 0px 8px;
        &.open{
            right:380px;
        }
        &.collapsed{
            right:0px;
        }
    }
}