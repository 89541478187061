.hover-card {
  position: absolute;
  z-index: 2;
  width: 400px;
  border: 1px solid grey;
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: rgba(51, 57, 62, 0.95);

  .hover-card-title {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;

    justify-content: space-between;
    font-family: 'Roboto';

    .hover-text-value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: white;
      text-align: left;
      border-bottom: 1px solid #fff;
      margin-bottom: 5px;

      .hover-card-heading {
        font-size: 20px;
        margin-bottom: 3px;
      }

      .hover-card-value {
        font-size: 15px;
        font-weight: 100;
        color: rgb(212, 212, 212);
        margin-bottom: 3px;

      }
    }
  }

  .hover-card-body {
    margin: 0px 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .hover-image {
      font-size: 0px;

      img {
        clip: rect(0px, 0px, 50px, 0px);
        margin-left: 0px;
        padding-left: 0px;
        height: 200px;
        width: 100%;
      }
    }

    .hover-text-value {
      display: flex;
      color: white;
      padding: 3px 0px;
      min-height: 30px;

      &:not(:last-child) {
        background-image: linear-gradient(to right, white 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 4px 1px;
        background-repeat: repeat-x;
      }

      .hover-card-heading {
        font-size: 14px;
        color: white;
        width: 50%;
      }

      .hover-card-value {
        font-size: 14px;
        width: 50%;
        font-weight: 100;
        color: rgb(212, 212, 212);
      }
    }
  }
}

#cesiumContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.cesium-viewer-bottom {
  text-align: center;
}