.table {
    color: rgb(153, 163, 174);
    background-color: transparent;
    font-size: 13px;
    pointer-events: all;
    height: 100%;

    .thead {
        color: white;
        background-color: #517cad;
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgb(49, 55, 71);

        .th {
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:not(:last-child) {
                border-right: 1px solid rgb(76, 89, 116);
            }

            .filter {
                padding: 5px 7px;

                input,
                select {
                    width: 100%;
                    color: white;
                    background-color: #517cad;
                    border: 1px solid #dcdddf;
                    border-radius: 3px;
                }

            }
        }
    }

    .tfoot {
        background-color: rgb(221, 221, 221);
        border-bottom: 1px solid rgb(49, 55, 71);
        color: black;
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;

        .td {
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .tbody {
        background-color: #dcdddf;
        color: black;
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid white;
        min-height: 80px;
        height: 93%;

        cursor: pointer;

        .tr {

            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover,
            &.highlighted {
                background-color: rgba(138, 140, 146, 0.85);
            }

            &:not(:first-child) {
                border-top: 1px solid rgba(138, 140, 146, 0.85);
            }

            &:not(::last-child) {
                border-bottom: 1px solid #313747;
            }

            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            .td {
                padding: 5px;
                text-transform: capitalize;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                .scroll-cell {
                    overflow-x: 'scroll'
                }
            }

        }
    }
}

.table-installation-title {
    width: 100%;
    display: flex;
    align-items: center;
    max-height: 30px;

    .table-installation-image {
        img {
            min-width: 30px;
            max-width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .table-installation-name {
        padding-left: 15px;
    }

    i {
        font-size: 18px;
        color: #5B5963;
        margin-left: auto;
    }
}

.rc-tooltip,
.rc-slider-tooltip {
    z-index: 5;
}

.button-bar {
    display: flex;
    z-index: 2;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgb(81, 124, 173);
    border: 1px solid rgba(243, 201, 201, 0.11);
    border-top: none;
    border-bottom-right-radius: 20px;

    .backbutton {
        color: white;
        padding-bottom: 10px;
        cursor: pointer;
    }

    .outward-handle {
        color: white;
        font-size: 20px;
        cursor: pointer;
    }
}