.entry-container {
    margin: 0px 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    a {
        .entry {
            &:hover {
                background-color: rgb(97, 115, 138);
            }
        }
    }

    .entry {
        display: flex;
        color: rgb(80,89,100);
        padding: 5px 0px;
        margin-bottom: 5px;
        min-height: 25px;

        &:not(:last-child) {
            background-image: linear-gradient(to right, white 33%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 4px 1px;
            background-repeat: repeat-x;
        }

        .entry-icon {
            padding-right: 10px;
            padding-top: 3px;
        }

        .entry-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            .entry-title {
                font-size: 14px;
                font-weight: 400;
                color: rgb(80,89,100);
                width: 50%;
            }

            .entry-subtitle {
                font-size: 14px;
                width: 50%;
                color: rgb(80,89,100);

                a {
                    color: rgb(80,89,100);
                    font-size: 14px;
                }
            }
        }
    }
}